
































import {Component, Prop, Vue} from 'vue-property-decorator';
import Job from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import {JobStatusIdentification, JobStatusType} from '@/misc/JobStatusIdentification';
import {showTimes, TimeDuration} from '@/helper/TimeDuration';
import moment from 'moment';

@Component({
  components: {
    UserInitialsComponent: () => import(
        '@/components/user/UserInitials.component.vue'),
  },
})
export default class CalendarEventTile extends Vue {
  private USERS_TO_SHOW = 3;

  @Prop({default: undefined})
  public event: { job: Job, userData: JobUserDataInterface[] } | undefined;

  /**
   *  Determines the color for the given event. The determined color is associated with an event status.
   */
  public getEventColor(event: any): string {
    // If no Job was defined, show empty space
    if (!event || typeof event.job !== 'object') {
      return 'empty-day-border';
    }

    // pointer should only be type of class, if the event is not empty
    let status = 'pointer';

    switch (JobStatusIdentification.identify(event.job)) {
      case JobStatusType.NOT_PLANNED:
        return status += ' status-not-planned';
      case JobStatusType.IN_FUTURE:
        return status += ' status-in-future';
      case JobStatusType.NOT_TOUCHED:
        return status += ' status-not-touched';
      case JobStatusType.IN_PROGRESS:
        return status += ' status-in-progress';
      case JobStatusType.DONE:
        return status += ' status-done';
      case JobStatusType.CANCELLED:
        return status += ' status-cancelled';
      case JobStatusType.AMBIGUOUS:
        return status += ' status-ambiguous';
    }
  }

  public showTime = (time: TimeDuration) => showTimes(time);

  private showJobDetails() {
    if (this.event) {
      this.$emit('showDetails', this.event);
    }
  }
}
